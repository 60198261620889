/**
 * Get the name of the targeted location
 *
 * @param {Node} node - HTML node
 * @returns {string} Name of the location
 */
export function getLocationName(node) {
    return node && node.attributes.name.value;
}
export function getLocationId(node) {
    return node && node.attributes.id.value;
}
/**
 * Get the name of the selected location
 *
 * @param {Object} map - Map of component
 * @param {string} locationId - Id of selected location
 * @returns {string} Name of the selected location
 */
export function getSelectedLocationName(map, locationId) {
    return (
        locationId &&
        map.locations.find(location => location.id === locationId).name
    );
}
export function getSelectedEstadoId(map, locationId) {
    return (
        locationId &&
        map.locations
            .find(location => location.id === locationId)
            .id.toUpperCase()
    );
}
export function getSelectedEstadoStatus(map, locationId) {
    return (
        locationId &&
        map.locations.find(location => location.id === locationId).status
    );
}
export function getSelectedEstadoCount(map, locationId) {
    return (
        locationId &&
        map.locations.find(location => location.id === locationId).count
    );
}
