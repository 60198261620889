<template>
    <div align="center" justify="center" class="ma-3">
        <v-card align="left" justify="left" class="my-3">
            <v-card-text class="pa-3 font-weight-light white black--text">
                <v-icon class="primary--text lighten-2 pa-2"> map</v-icon
                >Títulos > Mapa de Titulos
            </v-card-text>
        </v-card>
        <v-card class="my-6">
            <v-row align="center" justify="center">
                <v-col cols="12" sm="12" md="5">
                    <v-card :color="primaryColorFont" height="100" class="ml-3">
                        <div>
                            <v-subheader
                                class="white--text font-weight-light title"
                                sm="3"
                                md="10"
                                >Estado Selecionado</v-subheader
                            >
                            <v-col
                                class="
                                    ma-1
                                    pa-1
                                    ml-5
                                    white--text
                                    font-weight-black
                                    title
                                "
                            >
                                {{
                                    getSelectedLocationName(
                                        Brazil,
                                        selectedLocation
                                    )
                                }}
                            </v-col>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="5">
                    <v-card :color="primaryColorFont" height="100" class="ml-3">
                        <div class="">
                            <v-subheader
                                class="white--text font-weight-light title"
                                sm="3"
                                md="10"
                                >UF do estado</v-subheader
                            >
                            <v-col
                                class="
                                    ma-1
                                    pa-1
                                    ml-5
                                    white--text
                                    font-weight-black
                                    title
                                "
                            >
                                {{
                                    getSelectedEstadoId(
                                        Brazil,
                                        selectedLocation
                                    )
                                }}
                            </v-col>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="12" md="4">
                    <v-col cols="12" sm="12" md="12">
                        <!-- Inicio tabela resultados -->
                        <v-data-table
                            :headers="headers"
                            :items="todosStatus"
                            :loading="loading"
                            item-key="todosStatus"
                            class="elevation-2"
                            loading-text="Pesquisando títulos..."
                        >
                        </v-data-table>
                        <!-- Fim tabela resultados -->
                    </v-col>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-subheader
                        class="ml-4 grey--text font-weight-strong"
                        sm="3"
                        md="10"
                        >Mapa Completo:</v-subheader
                    >
                    <radio-svg-map
                        class="svg-map"
                        v-model="selectedLocation"
                        @mouseover="pointLocation"
                        @mouseout="unpointLocation"
                        @focus="focusLocation"
                        @blur="blurLocation"
                        @mousemove="moveOnLocation"
                        :map="Brazil"
                    />
                    <div class="tooltip" :style="tooltipStyle">
                        Estado: {{ pointedLocation }}
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import { RadioSvgMap } from 'vue-svg-map';
import Vue from 'vue';
import Brazil from '@svg-maps/brazil';
import MapsService from '@/services/MapsService';
import {
    getLocationName,
    getLocationId,
    getSelectedLocationName,
    getSelectedEstadoId,
    getSelectedEstadoStatus,
    getSelectedEstadoCount
} from '../utilities';

export default {
    name: 'home',
    components: {
        RadioSvgMap
    },
    methods: {
        pointLocation(event) {
            this.pointedLocation = getLocationName(event.target);
        },
        unpointLocation() {
            this.pointedLocation = null;
            this.contagemEstados = null;
            this.tooltipStyle = { display: 'none' };
        },
        moveOnLocation(event) {
            this.tooltipStyle = {
                display: 'block',
                top: `${event.clientY + 10}px`,
                left: `${event.clientX - 100}px`
            };
        },
        focusLocation(event) {
            this.loading = true;
            this.focusedLocation = getLocationName(event.target);
            this.uf = getLocationId(event.target);
            this.uf = this.uf ? this.uf.toUpperCase() : null;
            return new Promise((resolve, reject) => {
                try {
                    let statusService = new MapsService(Vue.http, this.$store);
                    statusService.listarStatus(this.uf).then(
                        response => {
                            if (response.status == 200) {
                                this.todosStatus = response.body;
                                this.loading = false;
                            }
                        },
                        error => {
                            console.log(error);
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        blurLocation() {
            this.focusedLocation = null;
        },
        getSelectedLocationName,
        getSelectedEstadoId,
        getSelectedEstadoStatus,
        getSelectedEstadoCount
    },
    mounted() {
        if (this.$store.getters.isPartner == 1) {
            this.primaryColorFont = '#757575';
        } else {
            this.primaryColorFont = this.$vuetify.theme.themes.light.primary;
        }
    },
    data() {
        return {
            todosStatus: [],
            Brazil,
            titulos: null,
            selectedLocation: null,
            pointedLocation: null,
            loading: false,
            focusedLocation: null,
            contagemEstados: null,
            uf: null,
            primaryColorFont: '#757575',
            tooltipStyle: null,

            headers: [
                {
                    text: 'Código Status',
                    sortable: false,
                    value: 'cd_status'
                },
                {
                    text: 'Status',
                    sortable: false,
                    value: 'ds_status'
                },
                {
                    text: 'Quantidade',
                    sortable: false,
                    value: 'qty'
                },
                { text: ' ', sortable: false, value: 'action' }
            ]
        };
    }
};
</script>
<style>
.svg-map {
    width: 100%;
    height: auto;
    stroke: #fff;
    stroke-width: 1;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: #002c5e;
}
.svg-map.location:hover,
:focus {
    fill: #53c7da;
    outline: 0;
}
.tooltip {
    position: fixed;
    width: 200px;
    padding: 10px;
    border: 1px solid darkgray;
    background-color: white;
    border-radius: 10px;
}
</style>
